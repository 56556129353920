import React from 'react';
import { courseCalendar } from '../../data/courseCalendar';
import PopupTrigger from '../PopupTrigger';

interface Props {
  className?: string;
}

export default function CourseCalendarSchedule({ className }: Props) {
  return (
    <div className={`course-calendar__schedule ${className}`}>
      {courseCalendar.map((cal, i) => (
        <PopupTrigger key={`cal-${i}`} title={cal.title}>
          <h3 className="h5">{cal.title}</h3>
          <table>
            <tbody>
              {cal.schedule.map((sched, j) => {
                if (sched.heading) {
                  return (
                    <tr key={`sched-${j}`}>
                      <td colSpan={2}>{sched.heading}</td>
                    </tr>
                  );
                }
                return (
                  <tr key={`sched-${j}`}>
                    <td>{sched.date}</td>
                    <td>{sched.desc}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </PopupTrigger>
      ))}
    </div>
  );
}
