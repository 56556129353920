export const courseListings = [
  {
    title: 'Salvation History',
    desc: [
      'This course helps students to understand the unity of God’s plan of salvation from Creation to the Second Coming.',
      'By a thorough overview of the Old and New Testaments, this course introduces Catholic exegetical approaches and theological interpretation, aiding students in reading Scripture as the Word of God.',
      'Students engage some comparative primary texts and grapple with historiographical questions that help them to demonstrate the reliability of the Bible.',
      'With a special focus on the themes of covenant and mission, the course illustrates how Jesus fulfills God’s promises and how He invites His followers to share in His work of evangelization.',
    ],
    credits: 3,
  },
  {
    title: 'The Creed: The Trinity, Christ, and the Church',
    desc: [
      'This course presents a synthetic summary of the symbolum fidei, the Christian Creed, with particular reference to its effective presentation in catechesis.',
      'The presentation follows that of the Catechism of the Catholic Church, making reference to other statements in minor creeds and magisterial documents, with particular emphasis given to the relevant portions of the Summa Theologiae of St. Thomas Aquinas.',
      'Throughout the course the unity and coherence of the fides quae are stressed.',
    ],
    credits: 3,
  },
  {
    title: 'The Church in the Ancient and Medieval World',
    desc: [
      'The Catechism of the Catholic Church teaches that “[t]he Church is in history, but at the same time she transcends it” (CCC 770).',
      'This course invites students to pursue deeper understanding of this teaching by means of an introduction to the basic contours of the Church’s historical pilgrimage from the Ascension of Christ to the fourteenth century.',
      'Taking political, cultural, and social circumstances into account, the course approaches this history primarily in terms of evangelization, that is, the reception, embodiment, articulation, and transmission of the inexhaustible Mystery of the Gospel.',
      'The course focuses on Christianity’s initial expansion, the emergence of distinctively Christian modes of thought and life, and reconfigurations of Christian culture in response to new challenges.',
    ],
  },
  {
    title: 'Jesus and the Gospels',
    desc: [
      'Among all the books of the Bible, the Gospels have “a special preeminence,” according to the Second Vatican Council, “for they are the principal witness for the life and teaching of the incarnate Word” (Dei Verbum, 18).',
      'This course examines Jesus’ life and mission in light of the Synoptic Gospels and the Gospel of John.',
      'Students engage insights from historical research into the life of Jesus and the world of first-century Judaism as well as gain a deeper understanding of the Gospels as narrative.',
      'In addition, the Gospels are also studied for their perennial theological and spiritual significance.',
    ],
    credits: 3,
  },
  {
    title: 'Mystagogy: Liturgy and the Sacraments',
    desc: [
      'Mystagogy is the ancient practice of learning to “see” the invisible Mystery made present in the visible signs of the sacraments.',
      'In this course, the Catechism and other sources of mystagogical practice serve as guides for a deeper knowledge of the plan of God made present in these wonderful gifts.',
      'After a theological and liturgical study of Christian worship, we gaze into each of the seven sacramental mysteries, learning to decode the signs they employ to dispose us better to receive what they reveal and communicate.',
      'In addition to the Catechism, texts considered include Ratzinger, Spirit of the Liturgy, and Corbon, Wellsprings of Worship.',
    ],
    credits: 3,
  },
  {
    title: 'The Church and Modernity',
    desc: [
      'The fall of Constantinople (1453), the publication of Gutenberg’s Bible (1454), and the discovery of the Americas (1492) signaled the end of the era of Latin Christendom and the beginning of the age we call modern.',
      'This period has been shaped by the founding and steady growth of a secular replacement for Christendom, first in Europe, then in North America, and, concurrently, the spread of the Gospel and the growth of the Church in the East and the Global South.',
      'This course will offer a narrative of the modern period down to the present, with special emphasis on the progress of evangelization, the saints and martyrs as teachers and models of Catholic thought and life, and the Church’s response to secularism.',
    ],
  },
  {
    title: 'Pauline Literature',
    desc: [
      'This course considers the life and writings of St. Paul, exploring his Jewish origins, life-changing conversion, and vocation.',
      'Students encounter St. Paul’s writings in context and understood both as essential to the Church’s teaching and in light of varying exegetical approaches through the centuries.',
      'Students see St. Paul as a rabbi and a theologian, and also as a catalyst in the early Church, who simultaneously criticizes and utilizes the Greco-Roman and Jewish worldviews in his proclamation of the gospel.',
      'This course treats St. Paul’s mastery of rhetoric and his pastoral sensitivity in a way that prepares students to formulate effective strategies for evangelization.',
    ],
  },
  {
    title: 'Moral and Spiritual Theology',
    desc: [
      'Through an investigation of human personhood and the vocation to love and beatitude, this course helps students to understand moral action and the habits of character it establishes.',
      'Students examine the Biblical foundation of Catholic moral teaching with particular attention given to the Sermon on the Mount, the new life realized in us by the gift of the Holy Spirit, and the virtues of Christian living.',
      'The course treats the dynamics of the moral law, sin, repentance, and grace, as well as prayer and the stages of the spiritual life.',
    ],
  },
  {
    title: 'Electives',
    desc: [
      'Students are required to take four elective courses.',
      'Elective course offerings vary by semester. Electives fall into the categories of Scripture, Church history, evangelization, philosophy, ancient Biblical languages, and the saints. ',
    ],
  },
];
