export const courseCalendar = [
  {
    title: 'Fall Term 2021',
    schedule: [
      { date: 'August 29', desc: 'Convocation' },
      { date: 'August 30', desc: 'Classes begin and Fall tuition is due' },
      { date: 'September 6', desc: 'Last day to ADD classes' },
      {
        date: 'September 24',
        desc: 'Last day to DROP classes, or change from credit to audit',
      },
      { date: 'October 25', desc: 'Last day to WITHDRAW from classes' },
      { date: 'November 22-26', desc: 'NO classes - Thanksgiving Break' },
      { date: 'December 13-17', desc: 'Exams' },
    ],
  },
  {
    title: 'Spring Term 2022',
    schedule: [
      { date: 'January 3-7', desc: 'Intensives' },
      { date: 'January 10', desc: 'Classes begin and Spring tuition is due' },
      { date: 'January 17', desc: 'Last day to ADD classes' },
      {
        date: 'February 7',
        desc: 'Last day to DROP classes, or change from credit to audit',
      },
      { date: 'March 4', desc: 'Last day to WITHDRAW from classes' },
      { date: 'April 12-18', desc: 'Holy Week/Easter (no classes)' },
      { date: 'April 25-29', desc: 'Exams' },
      { date: 'May 7', desc: 'Graduation' },
    ],
  },
  {
    title: 'Summer Term 2022',
    schedule: [
      { heading: '(Distance Education Courses)' },
      { date: 'May 30', desc: 'Classes begin and Summer tuition is due' },
      { date: 'June 6', desc: 'Last day to ADD classes' },
      { date: 'June 20', desc: 'Last day to DROP classes' },
      { date: 'July 11', desc: 'Last day to WITHDRAW from classes' },
      { date: 'July 25-30', desc: 'Exams' },
      { heading: '(On-Campus June Intensive Courses)' },
      { date: 'June 13-17', desc: 'Week 1' },
      { date: 'June 20-24', desc: 'Week 2' },
    ],
  },
];
