import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Tabs,
  TabList,
  Tab,
  TabPanelWrapper,
  TabPanel,
} from '../components/Tabs';
import { LogoCrest } from '../components/Logo';
import CourseListings from '../components/courses/CourseListings';
import CourseCalendarSchedule from '../components/courses/CourseCalendarSchedule';
import SEO from '../components/Seo';

interface Props {
  location: {
    state?: {
      tab?: string;
    };
  };
}

export default function CoursesPage({ location: { state } }: Props) {
  const [defaultTab, setDefaultTab] = useState<number | string>(
    state?.tab || 0
  );

  useEffect(() => {
    setDefaultTab(state?.tab || 0);
  }, [state]);

  return (
    <>
      <SEO
        title="Courses"
        description="The course of study in the Graduate School of Theology leads to the Master of Arts degree in Theology"
      />
      <main className="page-courses">
        <section className="container section-m-t page-m-b crest-bg-wrapper">
          <div className="row">
            <div className="col-xs-12 col-lg-10 col-lg-offset-1">
              <Tabs id="courses" defaultTab={defaultTab}>
                <TabList>
                  <Tab id="course-of-study">
                    <span>Course of Study</span>
                  </Tab>
                  <Tab id="course-listings">
                    <span>Course Listings</span>
                  </Tab>
                  <Tab id="course-calendar">
                    <span>Course Calendar</span>
                  </Tab>
                </TabList>
                <TabPanelWrapper>
                  <TabPanel>
                    <div className="crest-bg">
                      <LogoCrest />
                    </div>
                    <h2>Course of Study</h2>
                    <p className="narrow">
                      The course of study in the Graduate School of Theology
                      leads to the Master of Arts degree in Theology; it is
                      available on our campus in Denver or via distance
                      education. The program consists of three pillars:
                    </p>
                    <div className="pillars section-m-t--sm">
                      <ol>
                        <li className="pillar">
                          <h3 className="pillar__title">Sacred Scripture</h3>
                          <p className="pillar__body">
                            Students learn to express the narrative of salvation
                            history, explain the biblical foundations of
                            Catholic doctrine, interpret the texts in light of
                            tradition, and substantiate the reliability of
                            Sacred Scripture.
                          </p>
                        </li>
                        <li className="pillar">
                          <h3 className="pillar__title">Sacred Doctrine</h3>
                          <p className="pillar__body">
                            Each of our students develops a foundational
                            knowledge of the Catholic Church’s dogmatic,
                            sacramental, moral, and spiritual teaching as
                            exemplified by the Catechism of the Catholic Church.
                          </p>
                        </li>
                        <li className="pillar">
                          <h3 className="pillar__title">History and Mission</h3>
                          <p className="pillar__body">
                            Students come to grasp the main themes of Church
                            history, particularly in the West, with special
                            emphasis on evangelization and on the saints and
                            martyrs as teachers and models.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="crest-bg">
                      <LogoCrest />
                    </div>
                    <div className="course-listings">
                      <h2>Course Listings</h2>
                      <p className="narrow">
                        Students are expected to take the required courses in
                        the following order:
                      </p>
                      <CourseListings className="section-m-t--sm" />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="course-calendar row">
                      <div className="col-xs-12 col-sm-7 col-md-6 col-lg-6">
                        <div className="course-calendar__text">
                          <h2>Course Calendar</h2>
                          <p>
                            The majority of our online courses are filmed live
                            to our on-campus students and delivered
                            asynchronously to our distance education students
                            worldwide.
                          </p>
                          <p>
                            This means that our distance students have the
                            flexibility to view lectures whenever convenient to
                            them throughout their week, while staying on the
                            same week-to-week semester schedule as on-campus
                            students.
                          </p>
                          <blockquote>
                            On-campus students attend lectures between
                            <br />
                            <strong>
                              9:00 AM - 12:00 PM or 2:00 PM - 5:00 PM.
                            </strong>
                          </blockquote>
                          <CourseCalendarSchedule className="left" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-5 col-md-6 col-lg-6 course-calendar__img-col">
                        <CourseCalendarSchedule className="right" />
                        <div className="course-calendar__img">
                          <div className="course-calendar__img__shape course-calendar__img__shape--1">
                            <div />
                          </div>
                          <div className="course-calendar__img__inner">
                            <StaticImage
                              src="../images/graduation.jpg"
                              alt="Graduation"
                              // formats={['auto', 'webp', 'avif']}
                            />
                          </div>
                          <div className="course-calendar__img__shape course-calendar__img__shape--2 hide-xs show-sm">
                            <div />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabPanelWrapper>
              </Tabs>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
