import React from 'react';
import PopupTrigger from '../PopupTrigger';
import { courseListings } from '../../data/courseListings';

interface Props {
  className?: string;
}

export default function CourseListings({ className }: Props) {
  const courseListingsMid = Math.ceil(courseListings.length / 2);

  return (
    <div className={`content-columns ${className}`}>
      <div className="content-columns__row">
        <div className="content-columns__col">
          {courseListings.slice(0, courseListingsMid).map((course, i) => {
            const { title, desc, credits } = course;
            const number = i + 1;
            const numberedTitle = `${number}. ${title}`;
            return (
              <PopupTrigger key={`course-${i}`} title={numberedTitle}>
                <h3 className="h5">{numberedTitle}</h3>
                {desc.map((p, j) => (
                  <p key={`p-${j}`}>{p}</p>
                ))}
                {credits && (
                  <p
                    style={{
                      textAlign: 'right',
                      fontWeight: 500,
                    }}
                  >
                    {`${credits} Credit Hour${credits > 1 ? `s` : ''}`}
                  </p>
                )}
              </PopupTrigger>
            );
          })}
        </div>
        <div className="content-columns__col">
          {courseListings.slice(courseListingsMid).map((course, i) => {
            const { title, desc, credits } = course;
            const number = courseListingsMid + i + 1;
            const numberedTitle = `${number}. ${title}`;
            return (
              <PopupTrigger key={`course-${i}`} title={numberedTitle}>
                <h3 className="h5">{numberedTitle}</h3>
                {desc.map((p, j) => (
                  <p key={`p-${j}`}>{p}</p>
                ))}
                {credits && (
                  <p
                    style={{
                      textAlign: 'right',
                      fontWeight: 500,
                    }}
                  >
                    {`${credits} Credit Hour${credits > 1 ? `s` : ''}`}
                  </p>
                )}
              </PopupTrigger>
            );
          })}
        </div>
      </div>
    </div>
  );
}
